const idPortalApi = "98e686171ae0615efe8f1f4fdaa5ad93fe44e60b";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://saojoaobatista.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "saojoaobatista.ma.gov.br",
  NomePortal: "São João Batista",
  ufPortal: "MA",
  NomeTipoPortal: "Prefeitura Municipal", //Prefeitura ou Câmara
  TipoPortal: 3, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 4, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `https://www.planalto.gov.br/ccivil_03/leis/l9504.htm`,
  link_localize: `https://maps.app.goo.gl/zonBrqrfRdfSusnH8`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15938.078118526495!2d-44.817616447431696!3d-2.9532317563783668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7f59167a323f381%3A0xe420449c343f52ee!2zU8OjbyBKb8OjbyBCYXRpc3RhLCBNQSwgNjUyMjUtMDAw!5e0!3m2!1spt-BR!2sbr!4v1722362086126!5m2!1spt-BR!2sbr",
};
